a {
  text-decoration: none !important;
  color: inherit !important;
}

.product_container {
  border: 2px solid black;
  border-radius: 5%;
  padding: 20px;
  margin: 30px auto;
  width: 30%;
}

.parent {
  display: flex;
  flex-wrap: wrap; /* move element to  next line */
  max-width: 1200px;
  margin: 70px auto;
  width: 70%;
  padding: 20px;
  gap: 20px;
}

.product_image {
  object-fit: cover;
  height: 280px;
  width: 100%;
}

.product_price,
.price-in-cart {
  display: flex;
  align-content: center;
  margin-top: 20px;
}

.button_add {
  border-radius: 10px;
  width: 50%;
  color: black;
  background-color: rgb(250, 214, 11);
  cursor: pointer;
}

.button_container {
  display: block;
  justify-content: center;
}

.number_on_cart {
  position: absolute;
  top: -3px;
  right: 20px;
  font-size: 13px;
  border: 1px solid #ccc;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 20%;
  background-color: brown;
}

.cart_image {
  width: 90%;
  object-fit: cover;
}
.cart_list {
  list-style-type: none;
  border-bottom: 1px solid gray;
  margin-top: 20px;
  padding: 10px 0;
  width: 100%;
}

.name-in-cart,
.price-in-cart {
  color: rgba(0, 0, 0, 0.672);
}
.button-quanity {
  display: flex;
  justify-content: space-between;
}

.quanity-display {
  color: rgba(0, 0, 0, 0.672);
  font-size: 14px;
  padding: 0 16px;
}
.button-cart {
  font-size: 10px;
  border: none;
  border-radius: 5px;
  padding: 4px;
}
.userIcon {
  font-size: 40px;
}
