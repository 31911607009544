.checkoutParent {
  justify-content: center;
  display: flex;
  text-align: center;
  height: 600px;
  padding-top: 5em;
}
.checkItemContainer {
  margin: 20px 0;
  width: 70%;
  height: 90%;
  border-radius: 40px;
  border: 1px solid grey;
  padding: 20px;
  overflow-y: scroll;
}

.payment {
  padding: 10px;
  margin: 20px 0 0 30px;
  width: 25%;
  min-height: 500px;
  border-radius: 15px;
  border: 1px solid rgb(73, 68, 68);
  display: flex;
  flex-direction: column;
}

.mb-3 {
  width: 84%;
  size: 20px;
  margin-left: 25px;
  color: rgb(198, 196, 196);
}

.checkItemContainer::-webkit-scrollbar {
  display: none;
}

.flex {
  display: flex;
  justify-content: space-between;
  margin: 0 8px;
}

.totalPrice {
  margin-top: 25px;
}

.estimatedTotal {
  margin: 0;
  background-color: rgb(209, 210, 210);
  padding: 5px 5px 0 5px;
}

.blue {
  color: rgb(42, 42, 132);
}
