.loginBox {
  padding: 0 100px 100px 100px;
  display: flex;
  width: 100%;
}

.figure,
.formLogin {
  width: 50%;
  height: 50%;
  margin: 0;
}

.formLogin {
  padding: 100px;
  width: 50px;
  margin: auto;
}

.forgotBox {
  display: flex;
  flex-wrap: wrap;
}
.blue {
  color: blue;
}
.labelMember {
  text-align: center;
}
