.main-footer {
  margin-top: 200px;
  background: rgb(74, 73, 73);
  padding: 30px;
  color: rgb(219, 220, 221);
}

.footer-bottom {
  padding: 7px 0;
  margin: 50px 10px 0 0;
}
.list-unstyled li {
  font-size: 13px;
}

.contactH4,
.contactH41 {
  font-size: 20px;
}

.row div {
  width: 200px;
}
